<script>
import CategoryModal from "./components/ProductCategoryModal";
import ApiService from "@services/api.service";

export default {
  name: "Product",
  components: {
    CategoryModal
  },
  data() {
    return {
      table: {
        fields: [
          { key: "code", sortable: true },
          { key: "productCategoryDesc", sortable: true, label: "Product Group" },
          { key: "description", sortable: true, class: "text-right", label: "Product Name" },
          { key: "status", sortable: true, class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        sortBy: "code",
        filter: null
      },

      product: {
        productCategoryCode: null,
        description: null,
        divisionCode: null
      },

      parameter: {
        productCategoryList: []
      },
      editMode: false,
      showModal: false,
      showModalCategory: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    }
  },
  created() {
    this.loadTable();
    this.loadCategory();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("Product")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    loadCategory() {
      this.changeLoader(true);
      ApiService.get("Product/category")
        .then(resp => {
          this.parameter.productCategoryList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("Product", this.product)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        let payload = {
          code: this.product.code,
          description: this.product.description,
          productCategoryCode: this.product.productCategoryCode
        };
        ApiService.put("Product", payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("Product", null, qs)
        .then(() => {
          this.showToast("success", "Toggle Successful");
          this.loadTable();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    openDetail(selectedRow) {
      if (selectedRow) {
        this.product = selectedRow;
        this.editMode = true;
      }
      this.showModal = true;
    },
    replaceCategory(code) {
      this.product.productCategoryCode = code;
    },
    closeDetail() {
      this.showModal = false;
    },
    closeModal() {
      this.showModalCategory = false;
    },
    clearForm() {
      this.product = {
        code: null,
        productCategoryCode: null,
        divisionCode: null,
        description: null
      };
      this.editMode = false;
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #cell(status)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="btn btn-sm btn-wide mr-1" :class="data.item.isActive ? 'btn-secondary' : 'btn-info'" @click.stop="toggle(data.item.code)">{{
              data.item.isActive ? "Deactivate" : "Activate"
            }}</b-btn>
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="showModal" size="lg" centered hide-footer :backdrop="true" @hidden="clearForm">
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create Product` : `Edit Product` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4">
              <label>Code</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="product.code" class="form-control" :disabled="editMode" />
              <small>Product code</small>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4">
              <label>Product Group</label>
            </b-col>
            <b-col class="d-flex" md="8">
              <b-input-group>
                <template #append>
                  <b-button variant="primary" @click="showModalCategory = true"><feather-icon icon="EditIcon" size="12" /></b-button>
                </template>
                <b-form-select
                  v-model="product.productCategoryCode"
                  :options="parameter.productCategoryList"
                  label="description"
                  value-field="code"
                  text-field="description"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4">
              <label>Product Name</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="product.description" class="form-control" />
              <small>Product name</small>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <CategoryModal :is-shown="showModalCategory" @closeModal="showModalCategory = false" @saveSuccess="loadCategory()" />
  </div>
</template>
